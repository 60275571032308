<template>
    <b-overlay :show="showLoader">
        <b-card :title="$t('client.client_data')" v-if="client">
            <validation-observer ref="validation">
                <div class="row">

                    <b-form-group class="col-12 col-md-6" :label="$t('general.name')">
                        <validation-provider #default="{ errors }" :name="$t('general.name')" rules="required">
                            <b-form-input v-model="client.name"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group class="col-12 col-md-6" :label="$t('client.alias')">
                        <validation-provider #default="{ errors }" :name="$t('client.alias')" rules="required">
                            <b-form-input v-model="client.alias"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group class="col-12 col-md-6" :label="$t('client.address')">
                        <validation-provider #default="{ errors }" :name="$t('client.address')" rules="required">
                            <b-form-input v-model="client.address"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group class="col-12 col-md-6" :label="$t('client.tax_number')">
                        <validation-provider #default="{ errors }" :name="$t('client.tax_number')" rules="required|numeric|min:8|max:10">
                            <b-form-input v-model="client.tax_number"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group class="col-12 col-md-6" :label="$t('client.closing_time')">
                        <validation-provider #default="{ errors }" :name="$t('client.closing_time')" rules="required">
                            <b-form-timepicker v-model="client.closing_time"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group class="col-12" :label="$t('client.is_tax_payer')">
                        <b-form-checkbox switch v-model="client.is_tax_payer"/>
                    </b-form-group>

                    <b-form-group class="col-12" :label="$t('general.disabled')">
                        <b-form-checkbox switch v-model="client.disabled"/>
                    </b-form-group>

                    <b-form-group class="col-12" :label="$t('client.tier')">
                        <validation-provider #default="{ errors }"  name="tier" rules="required">
                            <v-select v-model="client.tier" :reduce="ele => ele.id" :clearable="false" :options="tiers" appendToBody/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                </div>
            </validation-observer>

            <div class="d-flex w-full justify-content-end">
                <b-button variant="primary" @click="editClient" :disabled="loading">
                    {{$t('general.confirm_changes')}} <b-spinner v-if="loading" class="ml-1" style="height: 15px; width: 15px"/>
                </b-button>
            </div>

        </b-card>

        <b-card :title="$t('user.users')">
            <div class="d-flex w-full justify-content-end">
                <b-button variant="primary" @click="addUserToClient">
                    {{$t('client.add_user_to_client')}}
                </b-button>
            </div>

            <basic-table :columns="columns" :data="users" v-slot="props" :enableRowMarking="true">
                <span v-if="props.column.displayType === 2" class="d-flex justify-content-center">
                    <template v-if="props.row.status === InvitationStatus.Confirmed">

                        <b-button variant="warning" class="mr-1" @click="editUser(props.row.connection)"><feather-icon icon="EditIcon"/></b-button>
                        <b-button variant="danger" class="mr-0" @click="deleteUser(props.row.id)"><feather-icon icon="TrashIcon"/></b-button>
                    </template>
                    <template v-else-if="props.row.status === InvitationStatus.Expired">
                        <b-button variant="secondary" class="mr-1" @click="resend(props.row.id,props.row)">Ponovno pošlji</b-button>
                        <b-button variant="danger" class="mr-0" @click="deleteInvitation(props.row.email)"><feather-icon icon="TrashIcon"/></b-button>
                    </template>
                </span>
                <span v-else-if="props.column.displayType === 1" class="d-flex justify-content-center">
                    {{props.row.name === '' ? '/' :props.row.name}}
                </span>
                <span v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">
                    <div v-for="item in invitationStatuses" :key="item.id">
                        <b-badge :variant="item.variant" class="w-100" v-if="item.id === props.row.status">
                            {{item.label}}
                        </b-badge>
                    </div>

                </span>

                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </basic-table>
        </b-card>

        <b-modal id="tiersModal" :title="$t('general.warning')">
            {{$t('error.cant_switch_tier')}}
            <template #modal-footer>
                <div class="d-flex justify-content-end">
                    <div>
                        <b-button @click="hide" variant="warning">
                            {{$t('client.ok')}}
                        </b-button>
                    </div>
                </div>
            </template>
        </b-modal>

        <AddUser ref="addUserToClientModal" @added="loadData" :client_id="client_id" :already_added_users="users"></AddUser>
    </b-overlay>
</template>

<script>
    import {
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BFormTimepicker,
        BCard, BButton, BSpinner, BOverlay, BBadge
    } from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import BasicTable from '@/views/components/BasicTable.vue'
    import AddUser from '@/views/Clients/AddUser.vue'
    import {captureException} from '@sentry/vue'
    import {Tiers} from '@/libs/enums/Tiers'
    import vSelect from 'vue-select'
    import {InvitationStatus} from '@/libs/enums/InvitationStatus'

    export default {
        computed: {
            InvitationStatus() {
                return InvitationStatus
            }
        },
        components: {
            BOverlay,
            vSelect,
            BSpinner,
            AddUser,
            BButton,
            BasicTable,
            BFormGroup,
            ValidationProvider,
            ValidationObserver,
            BFormTimepicker,
            BFormCheckbox,
            BFormInput,
            BCard,
            BBadge
        },
        data() {
            return {
                loading: false,
                originalTier: null,
                showLoader: false,
                client_id: '',
                client: undefined,
                users: [],
                tiers: [
                    {
                        label: 'Essential',
                        id: Tiers.Essential
                    },
                    {
                        label: 'Standard',
                        id: Tiers.Standard
                    },
                    {
                        label: 'Premium',
                        id: Tiers.Premium
                    }
                ],
                invitationStatuses: [
                    {
                        label: this.$t('invitation_status.confirmed'),
                        id: InvitationStatus.Confirmed,
                        variant:'success'
                    },
                    {
                        label: this.$t('invitation_status.confirmation_in_progress'),
                        id: InvitationStatus.ConfirmationInProgress,
                        variant:'info'
                    },
                    {
                        label: this.$t('invitation_status.expired'),
                        id: InvitationStatus.Expired,
                        variant:'warning'
                    }
                ],

                columns: [
                    {
                        label: this.$t('user.name'),
                        field: 'name',
                        displayType: 1,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: this.$t('user.email'),
                        field: 'email',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: this.$t('user.status'),
                        field: 'status',
                        displayType: 3,
                        filterOptions: {
                            enabled: false
                        }
                    },

                    {
                        label: this.$t('table.options'),
                        field: 'options',
                        displayType: 2,
                        filterOptions: {
                            enabled: false
                        },
                        sortable: false
                    }
                ]

            }
        },
        methods: {
            async resend(id, user) {
                try {
                    this.loading = true
                    await this.$http.post(`/api/reseller/v1/client/resend/${this.client_id}`, user)
                    this.$printSuccess(this.$t('general.resend_mail'))
                    await this.loadData()
                } catch (err) {
                    this.$printError(err)
                } finally {
                    this.loading = false
                }
            },
            async deleteInvitation(email) {
                try {
                    const confirmDelete = await this.$confirmDialog('Ali res želite izbrisati vrstico?')
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/reseller/v1/client/${this.client_id}/invitation/${email}`)
                    this.$printSuccess('Brisanje je bilo uspešno!')
                    await this.loadData()
                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)

                }
            },

            hide() {
                this.$bvModal.hide('tiersModal')
            },
            editUser(connection) {
                this.$refs.addUserToClientModal.open(true, connection)
            },
            addUserToClient() {
                this.$refs.addUserToClientModal.open(false, null)
            },
            async editClient() {
                try {
                    this.loading = true
                    const timestampString = `1970-01-31T${this.client.closing_time}.000Z`
                    const timestamp = this.$dayjs(timestampString)

                    const tmpObject = {
                        name: this.client.name,
                        address: this.client.address,
                        alias: this.client.alias,
                        tax_number: Number(this.client.tax_number),
                        closing_time: timestamp,
                        is_tax_payer: this.client.is_tax_payer,
                        disabled: this.client.disabled,
                        tier: this.client.tier
                    }

                    await this.$http.put(`/api/reseller/v1/client/${this.client.id}`, tmpObject)
                    this.$printSuccess(this.$t('success.on_edit'))
                } catch (error) {
                    if (error && error.response && error.response.data && (error.response.data.error === 'can\'t switch tiers')) {
                        this.$bvModal.show('tiersModal')
                        this.client.tier = this.originalTier
                    } else {
                        captureException(error)
                        this.$printError(this.$t('error.on_edit'))
                    }
                } finally {
                    this.loading = false
                }
            },
            async deleteUser(id) {
                const confirmDelete = await this.$confirmDialog(this.$t('table.delete_row_message'))
                if (!confirmDelete) return

                try {
                    this.showLoader = true
                    await this.$http.delete(`/api/reseller/v1/client/${this.client_id}/${  id}`)
                    this.$printSuccess(this.$t('success.on_delete'))
                    await this.loadData()
                } catch (error) {
                    captureException(error)
                    this.$printError(this.$t('error.on_delete'))
                } finally {
                    this.showLoader = false
                }
            },
            async loadData() {
                try {
                    this.showLoader = true
                    const clientResponse = this.$http.get(`/api/reseller/v1/client/${this.client_id}`)
                    const userResponse = this.$http.get(`/api/reseller/v1/users/list/${this.client_id}`)

                    const responses = await Promise.all([clientResponse, userResponse])
                    this.client = responses[0].data
                    this.originalTier = this.client.tier
                    this.client.closing_time = this.$dayjs(responses[0].data.closing_time).utc().format('HH:mm')
                    this.users = responses[1].data ?? []
                } catch (error) {
                    captureException(error)
                    this.$printError(this.$t('error.on_load_data'))

                    if (error && error.response && error.response.data && error.response.data.error) {
                        if (['no permission', 'mongo: no documents in result'].includes(error.response.data.error)) this.$router.push({name: 'error-404'})
                    }
                } finally {
                    this.showLoader = false
                }
            }
        },
        async mounted() {
            this.client_id = this.$route.params.client_id
            await this.loadData()
        }
    }
</script>
